import myRequest from '../http/index.js'
// 轮播图
export function bannerListAPI(params){
    return myRequest({
        url:"index.php/index/index/BankList",         
        params,
        method:"get"
      })
}
// 首页筛选
export function attributeListAPI(params){
    return myRequest({
        url:"index.php/index/index/attributeList",         
        params,
        method:"get"
      })
}
// 售卖站
export function cityListAPI(params){
  return myRequest({
      url:"index.php/index/index/saleList",         
      params,
      method:"get"
    })
}
// 首页推荐广告位
export function recommendListAPI(params){
  return myRequest({
      url:"index/index/BankRecommendList",         
      params,
      method:"get"
    })
}
// 接口：首页活动页
export function goodsIndexListAPI(params){
  return myRequest({
      url:"index.php/index/index/goodsIndex",         
      params,
      method:"post"
    })
}
// 活动赛事
export function activeViewAPI(params){
  return myRequest({
      url:"index.php/index/Article/about",         
      params,
      method:"get"
    })
}
// 首页底部游记推荐
export function trvaelArticleAPI(params){
  return myRequest({
      url:"index.php/index/index/articleTake",         
      params,
      method:"get"
    })
}
// 轮播图---详情
export function bannerinfoAPI(params){
  return myRequest({
      url:"index.php/index/index/BankView",         
      params,
      method:"get"
    })
}



